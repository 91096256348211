import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  IoHappy,
  IoShieldCheckmark,
  IoPause,
  IoPersonCircleOutline,
  IoEye,
  IoSunny,
  IoPaperPlane,
  IoTimer,
  IoCheckmark,
  IoSwapHorizontal,
  IoCalendar,
  IoFlash,
  IoImage,
  IoCodeWorking,
  IoCloudDone,
  IoAirplane,
  IoCard,
  IoHeart,
} from "react-icons/io5";
import { IoIosArrowRoundForward } from "react-icons/io";

import { useModal } from "../../components/useModal";

import {
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
  Button,
} from "../../components/util";
import {
  CustomerQuote,
  Modal,
  ImageGraphic,
  Feature,
} from "../../components/site";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Image from "../../components/image";

import PayHeroLogo from "../../images/logos/PayHero/PayHero_Full.svg";
import FromFlexiTime from "../../images/logos/FlexiTime/FromFlexiTime_Horiz_White.svg";
import DroppahLogo from "../../images/logos/Droppah/Droppah_Full_Black.svg";
import DroppahToPayHero_Graphic from "../../images/graphics/AddDroppahToPayHero.svg";

const CustomerName = styled.h4`
  color: #fff;
  position: absolute;
  bottom: 60px;
  left: 60px;
`;

const FeatureList = styled(Row)`
  --item-gap: 40px;

  width: calc(100% + var(--item-gap));

  > div {
    width: calc((100% / 3) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const CustomPlanCard = styled(Box)`
  padding: 10px 30px;
  border-left: solid 1px #ddd;
  border-right: solid 1px #ddd;
  max-width: 420px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 10px 15px;
  }
`;

const AddDroppah = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  width: 180px;

  > img {
    width: 100%;
  }
`;

const FromFlexiTimeBadge = styled.img`
  z-index: 99;
  height: 20px;
`;

const PayrollTimeClockLP = (props) => {
  const [calendlySource, setCalendlySource] = useState("");
  const [videoModal, setVideoModal] = useState(false);
  const [itemModalOpen, setItemModalOpen] = useModal();

  function handleModal(url, video) {
    setItemModalOpen(true);
    setCalendlySource(url);
    setVideoModal(video);
  }

  const benefits = [
    "Accurately pay variable hour employees",
    "Track to-the-minute timesheets & breaks",
    "See real time attendance from anywhere",
    "Automate public holiday payroll calculations",
    "Compliant leave calculations, even when work patterns change",
    "Add integrated rostering",
  ];

  const droppah_benefits = [
    "Build ideal rosters in a single click",
    "Enjoy enhanced time clock & real-time attendance monitoring",
    "Control labour costs & optimise coverage",
    "Accurately track time that syncs directly with payroll",
  ];

  const features = [
    {
      icon: <IoCalendar />,
      title: "Leave requests & approvals",
    },
    {
      icon: <IoFlash />,
      title: "Automated payday filing",
    },
    {
      icon: <IoImage />,
      title: "Employer branded payslips",
    },
    {
      icon: <IoSwapHorizontal />,
      title: "Automated paid & unpaid break compliance",
    },
    {
      icon: <IoCodeWorking />,
      title: "Deep Xero integration & cost tracking",
    },
    {
      icon: <IoCloudDone />,
      title: "Cloud-based and always up-to-date",
    },
    {
      icon: <IoAirplane />,
      title: "Put your payroll on AutoPilot",
    },
    {
      icon: <IoCard />,
      title: "Easy payments with POLi",
    },
    {
      icon: <IoHeart />,
      title: "Expert NZ-based support",
    },
  ];

  return (
    <Layout fullPage>
      <Seo
        title="Payroll & Photo Time Clock Software for Hourly Employees | PayHero"
        description="Take care of your team on payday with smart, modern and seriously compliant online payroll for NZ businesses. Get started today."
        pathname={props.location.pathname}
      />
      <Container css={{ position: "sticky", top: "0", zIndex: "999" }}>
        <Wrapper stackGap={10}>
          <Row alignCenter noBreak>
            <img
              src={PayHeroLogo}
              alt="PayHero | Compliant, Accurate & Easy to Use Payroll Software"
              css={{ maxWidth: "220px", maxHeight: "30px" }}
            />
            <FlexButtons className="hideOnMobile">
              <Button className="primary -md gtm-cta" to="/signup">
                Try 14 Days Free
              </Button>
              <Button
                className="grey -md gtm-cta"
                to="#!"
                onClick={() => {
                  handleModal("https://calendly.com/d/ynv-22p-2r5", false);
                }}
                atag
              >
                Book a Sales Call
              </Button>
            </FlexButtons>
            <div className="showOnMobile">
              <Button className="primary -md gtm-cta" to="/signup">
                Try 14 Days Free
              </Button>
            </div>
          </Row>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={70} noPaddingBottom>
          <Row stackGap={80} alignCenter>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h1>
                  <span className="-fontPrimary">
                    Integrated payroll & time clock
                  </span>{" "}
                  for hourly employees
                </h1>
                <h3 className="-fontNormal" css={{ color: "#333" }}>
                  Track hourly work with ease and automatically calculate pay
                  and entitlements for part time, casual, contract or shift
                  workers.
                </h3>
              </Box>
              <Box stackGap={15}>
                {benefits.map((item, i) => {
                  return (
                    <Row
                      alignCenter
                      noBreak
                      stackGap={10}
                      justify="flex-start"
                      key={i}
                      css={{ fontSize: "1.2rem" }}
                      centerOnMobile
                    >
                      <IoCheckmark
                        css={{
                          fontSize: "1.8rem",
                          color: "#5eb22e",
                          flex: "0 0 auto",
                        }}
                      />
                      <span>{item}</span>
                    </Row>
                  );
                })}
              </Box>
              <Box stackGap={20}>
                <FlexButtons>
                  <Button className="primary -lg gtm-cta" to="/signup">
                    Try 14 Days Free
                  </Button>
                  <Button
                    className="grey -lg gtm-cta"
                    to="#!"
                    onClick={() => {
                      handleModal("https://calendly.com/d/ynv-22p-2r5", false);
                    }}
                    atag
                  >
                    Book a Sales Call
                  </Button>
                </FlexButtons>
              </Box>
            </Box>
            <Box size={50} css={{ padding: "40px" }}>
              <ImageGraphic variant="1" />
              <Image
                filename="Hospo_Hero.png"
                alt="Payroll for Hospitality"
                centerImage
                addShadow
                rounded
              />
              <CustomerName>
                <b className="-handWritten">Panhead Custom Ales,</b>
                <br /> <span css={{ fontSize: "1rem" }}>PayHero Customer</span>
              </CustomerName>
            </Box>
          </Row>
          <Image
            filename="PayrollTimeclock_Customer_Logos.png"
            alt="PayHero Hourly Employee Customers"
            centerImage
          />
          <hr />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={120}>
          <Row stackGap={100} alignCenter mobileReverse>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Sync timesheets from mobile directly to payroll"
                filename="PayHero_Review_Hospo.png"
                addShadow
                centerImage
                rounded
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Stress-free payroll
                </h2>
                <h4 className="h2">The easy way to pay non-salaried staff</h4>
              </Box>
              <Feature
                icon={<IoHappy key={0} />}
                title="Fast, accurate and stress-free"
                description="Pay your staff in minutes and let our powerful payroll software do the hard work for you. Take care of all your wage and leave calculations, payslips and payday filing in a few clicks."
              />
              <Feature
                icon={<IoPaperPlane key={0} />}
                title="Online timesheets sent straight to payroll"
                description="Don’t waste time on paper timesheets and data entry. To-the-minute timesheets from the photo time clock app flow directly into payroll, saving time and increasing accuracy."
              />
              <Feature
                icon={<IoShieldCheckmark key={0} />}
                title="Built for compliance"
                description="Designed to simplify payroll for part time & variable hour staff and those with changing work patterns, PayHero follows the latest MBIE Holidays Act guidelines to ensure your employees maintain the right holiday entitlements."
              />
              <Feature
                icon={<IoSunny key={0} />}
                title="Automated public holiday calculations"
                description="PayHero determines whether a public holiday is an otherwise working day for an employee based on their recent work pattern and calculates their entitlements accordingly."
              />
            </Box>
          </Row>
          <FeatureList justify="center" isWrap>
            {features.map((item, i) => {
              return <Feature key={i} icon={item.icon} title={item.title} />;
            })}
          </FeatureList>
          <CustomerQuote
            quote="We've cut the time we spend doing payroll by 80%."
            name="Bridget Dunn"
            company="Prefab Eatery"
            pic="Prefab_Circle.png"
            large
            center
          />
          <Row stackGap={100} alignCenter>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Simple, accurate time tracking
                </h2>
                <h4 className="h2">
                  Record exact clock in and out times for fast, error-free
                  payroll
                </h4>
              </Box>
              <Feature
                icon={<IoPersonCircleOutline key={0} />}
                title="Start shifts with a selfie"
                description="Get your staff to clock in and out and record breaks with our photo time clock app. It's easy and fun for employees to use, increases your payroll accuracy and saves hours of admin time."
              />
              <Feature
                icon={<IoTimer key={0} />}
                title="Automated timesheets"
                description="Clock in and outs automatically update timesheets in your payroll. No more collecting paper timesheets, checking the hours have been added up correctly and typing them into your payroll system."
              />
              <Feature
                icon={<IoEye key={0} />}
                title="Real-time attendance"
                description="Monitor the day’s attendance & breaks in real-time. Keep a tab on who’s on the clock, taking a break, running late and working overtime."
              />
              <Feature
                icon={<IoPause key={0} />}
                title="Take a break"
                description="When your staff take a break, PayHero will figure out if it should be a paid 10-minute rest break or an unpaid 30-minute meal break. PayHero calculates the point at which the break is taken within the shift, the length of the break, and the total duration of the shift, to then determine whether it’s a paid or unpaid break."
              />
            </Box>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Clock In & Out | Photo Time Clock | PayHero"
                filename="PayHeroShift_LiveAttendance.png"
                maxWidth={600}
                addShadow
                centerImage
                rounded
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="The photo time clock creates a sense of fairness by accurately recording hours, so both the staff and the owner know they’re not being taken advantage of."
            name="Paul Newson"
            company="Brazen Clothing"
            pic="Brazen_Circle.png"
            large
            center
          />
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <Box stackGap={60}>
            <div className="-textCenter">
              <h2>Pricing</h2>
              <h3 css={{ color: "#333" }} className="-fontNormal">
                No Hidden Costs · Easy Set Up · Cancel Anytime
              </h3>
            </div>
            <CustomPlanCard stackGap={40} className="-center">
              <Box centerOnMobile stackGap={10}>
                <Row stackGap={15} alignCenter justify="center">
                  <h1 css={{ fontSize: "6rem", color: "#00adef" }}>$5</h1>
                  <h4 css={{ color: "#999" }}>
                    per employee
                    <br /> per month
                  </h4>
                </Row>
                <h4
                  className="-fontBold -textCenter"
                  css={{ marginBottom: "0", color: "#00adef" }}
                >
                  + $39 base fee per month
                </h4>
              </Box>
              <Button
                className="primary -lg gtm-cta -center -full"
                to="/signup"
              >
                Get Started
              </Button>
            </CustomPlanCard>
            <Box>
              <p className="-textCenter" css={{ color: "#999" }}>
                Prices are in NZD and exclude GST.
              </p>
              <p className="-textCenter" css={{ color: "#999" }}>
                See full pricing details <Link to="/pricing">here</Link>.
              </p>
            </Box>
          </Box>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Row stackGap={80} alignCenter mobileReverse>
            <Box size={55}>
              <Image
                filename="DroppahHero_Full_SQ.png"
                alt="Droppah | Rosters, Time Tracking & Attendance Software"
                centerImage
                addShadow
              />
            </Box>
            <Box size={45} stackGap={40} centerOnMobile>
              <Box stackGap={30}>
                <h2 className="h4 -fontDark -fontNormal">
                  Integrated Rostering
                </h2>
                <Box stackGap={10}>
                  <Row alignCenter stackGap={15} justify="flex-start">
                    <img
                      src={PayHeroLogo}
                      alt="PayHero | Compliant, Accurate & Easy to Use Payroll Software"
                      height="25"
                    />
                    <span>+</span>
                    <img
                      src={DroppahLogo}
                      alt="Droppah | Rosters, Time Tracking & Attendance Software"
                      height="25"
                    />
                  </Row>
                  <h4 className="h1">
                    Create the perfect roster{" "}
                    <span className="-fontPrimary">
                      and pay your staff right
                    </span>
                  </h4>
                </Box>
              </Box>
              <Box stackGap={15} centerOnMobile>
                {droppah_benefits.map((item, i) => {
                  return (
                    <Row
                      alignCenter
                      noBreak
                      stackGap={10}
                      justify="flex-start"
                      key={i}
                      css={{ fontSize: "1.2rem" }}
                      centerOnMobile
                    >
                      <IoCheckmark
                        css={{
                          fontSize: "1.8rem",
                          color: "#5eb22e",
                          flex: "0 0 auto",
                        }}
                      />
                      <span>{item}</span>
                    </Row>
                  );
                })}
              </Box>
              <Link className="link-arrow -lg" to="/rostering">
                Learn more about rostering <IoIosArrowRoundForward />
              </Link>
              <AddDroppah className="hideOnTablet">
                <img
                  src={DroppahToPayHero_Graphic}
                  alt="Add Droppah to PayHero"
                />
              </AddDroppah>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container className="secondary">
        <Wrapper>
          <Box stackGap={40}>
            <Box stackGap={7} className="-textCenter">
              <h2 className="h1">
                Manage complex payroll{" "}
                <span className="-fontPrimary">& pay employees right</span>
              </h2>
              <h3 className="-fontNormal">
                Talk to the team to see how PayHero can help your business.
              </h3>
            </Box>
            <FlexButtons justify="center">
              <Button className="primary -lg gtm-cta" to="/signup">
                Try 14 Days Free
              </Button>
              <Button
                className="dark -lg gtm-cta"
                to="#!"
                onClick={() => {
                  handleModal("https://calendly.com/d/ynv-22p-2r5", false);
                }}
                atag
              >
                Book a Sales Call
              </Button>
            </FlexButtons>
          </Box>
          <FromFlexiTimeBadge className="-center" src={FromFlexiTime} />
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL={calendlySource}
        embedTitle="Introducing PayHero | Video"
        handleClose={() => setItemModalOpen(false)}
        isVideo={videoModal}
      />
    </Layout>
  );
};

export default PayrollTimeClockLP;
